<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "AJOUTER UN NOUVEAU" : "METTRE À JOUR L'" }} APPORTEUR D'AFFAIRE</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <div class="p-6">
        <!-- NAME -->
        <vs-input   label="Nom" v-model="last_name" class="mt-5 w-full" name="first_name" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>

        <!-- Prenons-->
        <vs-input    label="Prénon" v-model="first_name" class="mt-5 w-full" name="last_name" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

        <!-- Nom d'utilsateur-->
        <vs-input    label="Nom d'utilisateur" v-model="username" class="mt-5 w-full" name="username" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>

        <p > Sexe </p>
        <v-select
          v-validate="'required'"
          name="sexe"
          :reduce="rep => rep.key"
          class="w-full" label="name"
          v-model="gender"
          :options="genders">
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('sexe')">{{ errors.first('sexe') }}</span>

        <!-- Date de naissance-->
        <p  class="pt-4">Date de naissance </p>
          <flat-pickr label="Date" v-model="date"  name="date" class="mt-5 w-full" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('date')">{{ errors.first('date') }}</span>

        <!--EMAIL-->
        <vs-input v-validate="'required|email'" label="Email" name="email" type="email" v-model="email" class="w-full mt-6" />
        <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>

        <!-- Telephone-->

        <p  class="pt-4">Téléphone </p>

        <vue-tel-input
          v-on:country-changed="countryChanged"
          inputOptions.placeholder="Telephone"
          :selectedCountryCode="true"
          v-model="phone"
          data-vv-validate-on="blur"
          v-validate="'required|min:8|max:16'"
        >
        </vue-tel-input>

        <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>


        <vs-select v-model="country" autocomplete label="Pays" class="mt-5 w-full" name="pays" v-validate="'required'">
          <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in countries" />
        </vs-select>
        <span class="text-danger text-sm" v-show="errors.has('pays')">{{ errors.first('pays') }}</span>

        <vs-input
          v-validate="'required'"
          data-vv-validate-on="blur"
          label="Ville"
          name="Ville"
          v-model="city"
          class="w-full" />
        <span class="text-danger text-sm" v-show="errors.has('Ville')">{{ errors.first('Ville') }}</span>

      </div>

    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button  class="mr-6" @click="submitData" :disabled="!isFormValid" >Soumettre</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Annuler</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

// For custom error message
import { Validator } from 'vee-validate'
const dict = {
  custom: {
    first_name: {
      required: 'Le champ nom est obligatoire'
    },
    last_name: {
      required: 'Le champ prénoms est obligatoire'
    },
    birthDate: {
      required: 'Le champ date de naissance est obligatoire'
    },
    email: {
      required: 'Le champ email est obligatoire'
    },
    date: {
      required: 'Le champ date est obligatoire'
    },
    phone: {
      required: 'Le champ telephone est obligatoire'
    },
    username: {
      required: 'Le champ nom d\'utilisateur est obligatoire'
    },
    country: {
      required: 'Le champ pays est obligatoire'
    },
    city: {
      required: 'Le champ ville est obligatoire'
    },
    gender: {
      required: 'Le champ sexe est obligatoire'
    }
  }
}

// register custom messages
Validator.localize('en', dict)

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {

    city (value) {
      if (value) {
        const parent = value
        this.input.recipients = []
        this.$http.post('recipient_filter/', {parent})
          .then((response) => {
            this.recipients = response.data
          })
          .catch((error) => { console.log(error) })
      }

    },
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const { last_name, id, first_name, email, birthDate, username, phoneNumber, city, gender, country} = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.last_name = last_name
        this.username = username
        this.first_name  = first_name
        this.city = city
        this.email  = email
        this.country  = country.id
        this.gender  = gender
        this.date = birthDate
        this.phone = phoneNumber
        this.initValues()
      }
    }
  },
  data () {
    return {
      date: null,
      dataId: null,
      last_name : '',
      city : '',
      first_name : '',
      email :'',
      username:'',
      country:'',
      countryID:'',
      gender:'',
      genders: [
        {
          key: 'male',
          name: 'Masculin'
        },
        {
          key: 'female',
          name: 'Féminin'
        }
      ],
      countries:[],
      // cities:[],
      phone: '',
      bindProps: {
        inputOptions: {
          showDialCode: true
        }
      },

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  components: {
    VuePerfectScrollbar, flatPickr
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          this.$validator.reset()
          this.initValues()
        }
      }
    },
    isFormValid () {
      return !this.errors.any() &&
        this.last_name &&
        this.first_name  &&
        this.username  &&
        this.email  &&
        this.date  &&
        this.country  &&
        this.gender  &&
        this.city  &&
        this.phone
    }

  },

  methods: {
    getAllContries () {
      //delete this.$http.defaults.headers.common['Authorization']
      this.$http.get('countries/')
        .then((response) => {
          this.countries = response.data
        })
        .catch(() => {
        })
    },
    initValues () {
      if (this.data.id) return
      this.last_name = ''
      this.first_name  = ''
      this.username  = ''
      this.email  = ''
      this.country  = ''
      this.date = ''
      this.gender = ''
      this.phone = ''
      this.city = ''
    },
    countryChanged (country) {
      const countryCode = country.dialCode
      // this.phone = '+'+country.dialCode
      this.$http.post('code-country/', { countryCode })
        .then((response) => {
          this.country = response.data[0].id
          // console.log("voir le pays",response.data[0].name)
        })
        .catch(() => {})


    },

    submitData () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            id: this.dataId,
            last_name:this.last_name,
            first_name:this.first_name,
            username: this.username,
            birthDate: this.date,
            phoneNumber: this.phone,
            country: this.country,
            gender: this.gender,
            defaultCountryCode:'+228',
            email: this.email,
            city: this.city
          }

          if (this.dataId !== null && this.dataId >= 0) {
            this.$vs.loading()
            this.$store.dispatch('dataListContributors/updateContributors', obj)
            this.$store.dispatch('dataListContributors/fetchContributors')
              .then(() => {
                this.$vs.notify({
                  title: 'MODIFICATION',
                  text: 'Apporteur modifié',
                  color: 'success',
                  position:'top-center'
                })
                this.$vs.loading.close()
              })
              .catch(err => { console.error(err) })


          } else {
            delete obj.id
            obj.popularity = 0
            this.$vs.loading()
            this.$store.dispatch('dataListContributors/addContributors', obj)
              .then(() => {
                this.$store.dispatch('dataListContributors/fetchContributors')
                this.$vs.loading.close()
                this.$vs.notify({
                  title: 'ENREGISTREMENT',
                  text: 'Enregistrement effectué',
                  color: 'success',
                  position:'top-right'
                })
              })
              .catch((error) => {
                const clefs = Object.keys(error.response.data)
                for (let i = 0; i < clefs.length; i++) {
                  const item = clefs[i]
                  // let libelle = ''

                  if (item === 'username') {
                    // libelle = "Nom d'utilisateur"
                    this.$vs.notify({
                      time:4000,
                      title: 'ATTENTION',
                      text: 'Nom d\'utilisateur déjà utilisé',
                      color: 'warning',
                      position:'top-center'
                    })
                  } else if (item === 'email') {
                    // libelle = 'Email'
                    this.$vs.notify({
                      time:4000,
                      title: 'ATTENTION',
                      text: 'L\'Email déjà utilisé ',
                      color: 'warning',
                      position:'top-center'
                    })
                  } else if (item === 'phoneNumber') {
                    // libelle = 'Teléphone'
                    this.$vs.notify({
                      time:4000,
                      title: 'ATTENTION',
                      text: 'Le Numéro de téléphone déjà utilisé ',
                      color: 'warning',
                      position:'top-center'
                    })
                  }

                }
                this.$vs.loading.close()
                this.$vs.notify({
                  time:8000,
                  title: 'ENREGISTREMENT',
                  text: 'L\'enregistrement a écchoué',
                  color: 'danger',
                  position:'top-right'
                })
              })

          }
          this.$emit('closeSidebar')
          this.initValues()
        }
      })
    }
  },

  created () {
    this.getAllContries()
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
